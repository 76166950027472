<template>
    <dx-util-popup
    ref="draftTasksPopupRef"
    height="auto"
    width="800px"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="draftTasksPopupShown"
    @hidden="draftTasksPopupHidden"
  >
    <div>

      <table>
      <thead>
        <tr>
          <th>Day</th>
          <th>Date</th>
          <th>Task Type</th>
          <th>Company Name</th>
          <th>Subject</th>
          <th>Assign To</th>
          <th>Store Name</th>
          <th>Priority</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(task, index) in tasks" :key="index">
          <td>{{ task.day }}</td>
          <td>{{ task.date }}</td>
          <td>{{ task.taskType }}</td>
          <td>{{ task.companyName }}</td>
          <td>
            {{ task.subject }}
          </td>
          <td>
            <dx-util-select-box
              id="store"
              v-model="task.assignTo"
              :data-source="companyUsers"
              :search-enabled="true"
              display-expr="fullName"
              value-expr="id"
            />
          </td>
          <td>
            <dx-util-select-box
              id="store"
              v-model="task.storeId"
              :data-source="taskData.companyStores"
              :search-enabled="true"
              display-expr="storeName"
              value-expr="storeId"
            />
          </td>
          <td>
            <dx-util-select-box
              v-model="task.priority"
              :data-source="taskPriorities"
              display-expr="text" value-expr="value"
              placeholder="Select priority"
            />
          </td>
          <td>
            <dx-util-button icon="trash" type="danger" @click="deleteTask(task)" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-right p-2">
      <dx-util-button text="Generate Tasks" type="success" @click="generateTasks" />
    </div>
    </div>
  </dx-util-popup>
</template>

<script>
import { TaskPriorityEnum } from '@/enums'
import { getPriorityEnumList } from '@/enums/taskPriority.enum'
import companyService from '@/http/requests/company/companyService'
import UserSearchFilter from '@/http/models/search-filters/UserSearchFilter'
import userManagementService from '@/http/requests/system/userManagementService'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import Pager from '@/@core/dev-extreme/utils/pager'
import useCurrentUser from '@/libs/app/current-user'

export default {
  props: {
    componentId: {
      type: String,
      default: '',
    },
    taskData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      tasks: [],
      companyUsers: [],
      taskPriorities: this.prepareOptions(getPriorityEnumList()),
    }
  },
  computed: {
    draftTasksPopup() {
      return this.$refs.draftTasksPopupRef.instance
    },
    quantityOptions() {
      return {
        format: '#,##0',
        min: 0,
      }
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.draftTasksPopup.show()
        this.tasks = this.parseTasks(this.taskData)
        this.getUsersList(this.userCompanyId)
      },
    },
  },
  setup() {
    const { userCompanyId } = useCurrentUser()
    return {
      userCompanyId,
    }
  },
  methods: {
    draftTasksPopupShown() {
      this.popupTitle = 'Create Tasks (Today to Sunday)'
    },
    draftTasksPopupHidden() {
      this.$emit('update-task-calendar')
    },
    prepareOptions(enumList) {
      return enumList.filter(option => option.value !== 'ALL')
    },
    deleteTask(task) {
      const taskIndex = this.tasks.findIndex(item => item.id === task.id)
      if (taskIndex !== -1) {
        this.tasks.splice(taskIndex, 1)
      }
    },
    parseTasks(tasksRawData) {
      const tasks = []
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      let currentDate = new Date()

      const createTaskObject = (day, taskType, date, rawData) => ({

        id: uuidv4(),
        day: day,
        date: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'), // Format date using Moment.js
        taskType: taskType,
        assignToFullName: rawData.assignToFullName,
        calendarId: rawData.id,
        assignTo: rawData.assignTo,
        companyName: rawData.companyName,
        companyId: rawData.companyId,
        storeId: rawData.storeId,
        storeName: rawData.storeName,
        priority: TaskPriorityEnum.MEDIUM.value,
        subject: `${taskType} for ${rawData.companyName}`,
      })

      Object.keys(tasksRawData.days).forEach(day => {
        if (tasksRawData.days[day] && tasksRawData.days[day].taskTypes) {
          if (currentDate.getDay() === daysOfWeek.indexOf(day)) {
            tasksRawData.days[day].taskTypes.forEach(taskType => {
              tasks.push(createTaskObject(day, taskType, currentDate, tasksRawData))
            })
          }
        }
      })

      currentDate = new Date() // Reset currentDate

      while (currentDate.getDay() !== 0) { // Loop until Sunday
        currentDate.setDate(currentDate.getDate() + 1) // Increment date by one day

        const day = daysOfWeek[currentDate.getDay()]
        if (tasksRawData.days[day] && tasksRawData.days[day].taskTypes) {
          tasksRawData.days[day].taskTypes.forEach(taskType => {
            tasks.push(createTaskObject(day, taskType, currentDate, tasksRawData))
          })
        }
      }

      return tasks
    },
    async generateTasks() {
      const payload = this.tasks.map(task => ({
        assignTo: task.assignTo,
        companyId: task.companyId,
        subject: task.subject,
        priority: task.priority,
        date: task.date,
        storeId: task.storeId,
        taskType: task.taskType,
        calendarId: task.calendarId,
      }))
      // implement method
      await companyService.generateTasks({
        tasks: payload,
      })
    },
    getUsersList(companyId) {
      UserSearchFilter.setDefaultFilters()
      UserSearchFilter.companyId = companyId
      UserSearchFilter.activated = true
      const filters = UserSearchFilter.getFilters()
      const pager = new Pager()
      userManagementService
        .getUserInfoBasicByQuery(filters, pager.staticPageable)
        .then(response => {
          const data = response.data
          this.companyUsers = data.content
        })
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
th, td {
  padding: 10px;
}

.dark-layout thead {
  border-bottom: #161D31 1px solid;
}

.light-layout thead {
  border-bottom: #bccdfd 1px solid;
}

.bordered-layout thead {
  border-bottom: #bccdfd 1px solid;
}

th {
  color: orange;
}

table {
  border-collapse: collapse;
}

.dark-layout  tr {
  border-bottom: 1px solid rgb(54, 70, 113);
}

.light-layout  tr {
  border-bottom: 0.5px solid rgb(146, 147, 148);
}

.bordered-layout  tr {
  border-bottom: 0.5px solid rgb(146, 147, 148);
}

th:last-child,
td:last-child {
  width: 50px !important;
}

</style>
